import React, { useState } from 'react'
import { connect } from 'react-redux'

import { tryPassword } from '../state/store'

const gate = ({ hasProtectedAccess, dispatch }) => {
  const [password, setPassword] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    dispatch(tryPassword(password));
  };

  return (
    <div className="password">
      <div className="password__inner">
        <h4>Password Protected</h4>

        <form className="password__form" onSubmit={onSubmit}>
          <input
            name="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />

          <button type="submit">Enter</button>
        </form>
      </div>
    </div>
  );
};

export default connect(state => ({
  hasProtectedAccess: state.hasProtectedAccess
}), null)(gate);
