function isMobile() {
  if (typeof window !== 'undefined' && typeof navigator.userAgent !== 'undefined') {
    return window && window.innerWidth <= 1024 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  } else {
    return false
  }
}

function hasHover() {
  return (typeof window !== 'undefined' && typeof matchMedia !== 'undefined' && matchMedia('(hover:hover)').matches)
}

export {
  isMobile,
  hasHover
}
