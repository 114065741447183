import React from 'react'
import { bindAll } from 'lodash'

export const HoverContext = React.createContext()

class HoverGroup extends React.Component {
  constructor(props) {
    super(props)

    bindAll(this, ['onMouseLeave'])

    this.state = {
      enable: false,
      current: false,
      onMouseEnter: (id) => () => {
        if (!this.state.enable) return true
        this.setState({current: id})
        this.props.onCurrent(id)
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', () => {
        this.setState({enable: true})
      }, {once: true})
    }
  }
  onMouseLeave() {
    if (!this.state.enable) return true
    this.setState({current: false})
  }
  render() {
    return (
      <HoverContext.Provider value={this.state}>
        <div className="hover-group" onMouseLeave={this.onMouseLeave}>
          {this.props.children}
        </div>
      </HoverContext.Provider>
    )
  }
}

export default HoverGroup