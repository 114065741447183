import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({
               title = null,
               description = null,
               image = null,
               pathname = null,
               article = false,
             }) => (
  <StaticQuery
    query={query}
    render={({
               site: {
                 siteMetadata: {
                   defaultTitle,
                   titleTemplate,
                   defaultDescription,
                   siteUrl,
                   defaultImage
                 },
               },
             }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      };

      return (
        <Helmet defaultTitle={seo.title} titleTemplate={titleTemplate}>
          <title>{title}</title>
          <meta name="description" content={seo.description} />
          <link rel="canonical" href={seo.url} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={seo.url} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      );
    }}
  />
)

export default SEO

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }`