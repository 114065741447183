import React from "react"
import { bindAll, uniqueId } from "lodash"

import ObjectFit from "./object-fit"
import preload from "../../utils/preload"

import { PreviewsContext } from "./previews"
import { isMobile } from "../../utils/device"

class Preview extends React.Component {
  constructor(props) {
    super(props)
    this.el = React.createRef()
    this.video = React.createRef()
    this.objectFit = React.createRef()

    this.uId = uniqueId("preview")

    bindAll(this, [
      "onLoadedMetaData",
      "onLoadedData",
      "onMouseEnter",
      "onCanPlay",
      "onClickSound",
    ])

    this.state = {
      muted: true,
      enable: false,
      ready: false,
      playing: false,
      canPlay: false,
      hasPlayed: false,
      preloaded: false,
      lazyReady: false,
    }

    this.diff = (nextState, state) => (key) => {
      return nextState[key] !== state[key]
    }

    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", this.onMouseMove.bind(this))
    }
  }

  onMouseMove() {
    window.removeEventListener("mousemove", this.onMouseMove.bind(this))
    !this.unmounting && this.setState({ enable: true })
  }

  _togglePlay(play) {
    const { current } = this.video
    if (!current) return
    const action = play ? "play" : "pause"
    current[action]()
    // play comes after 'canplay' event
    if (play && this.state.canPlay) {
      this.playTimeoutId = setTimeout(() => {
        // delay to prevent black video flashing
        this.setState({ hasPlayed: true })
      }, 300)
    }
  }

  onLoadedMetaData() {
    this.setState({ ready: true }, () => {
      this.objectFit.current.fit()
    })
    this.props.onReady && this.props.onReady()
  }

  onLoadedData() {
    this.objectFit.current.fit()
    // this.setState({ready: true}, () => {
    //   this.objectFit.current.fit()
    // })
    // this.props.onReady && this.props.onReady()
  }

  onCanPlay() {
    this.setState({ canPlay: true }, () => {
      if (this.state.playing) {
        // canPlay comes after 'play' trigger
        this.canPlayTimeoutId = setTimeout(() => {
          // delay to prevent black video flashing
          this.setState({ hasPlayed: true })
        }, 3000)
      } else if (this.props.autoPlay) {
        this.play()
      }
    })
  }

  onMouseEnter() {
    if (!this.state.enable) return true
    this.play()
  }

  onClickSound() {
    const muted = !this.state.muted
    this.setState({ muted })
  }

  play() {
    this.setState({ playing: true })
  }

  pause() {
    this.setState({ playing: false })
  }

  mute() {
    this.setState({ muted: true })
  }

  componentDidMount() {
    if (this.props.cover) {
      preload(this.props.cover).then((src) => {
        this.setState({ preloaded: true })
      })
    }
    if (this.props.lazy) {
      this.el.current.addEventListener(
        "lazybeforeunveil",
        this.onLazybeforeunveil.bind(this)
      )
    }
  }

  componentWillUnmount() {
    this.unmounting = true
    window.removeEventListener("mousemove", this.onMouseMove.bind(this))
    this.canPlayTimeoutId && clearTimeout(this.canPlayTimeoutId)
    this.playTimeoutId && clearTimeout(this.playTimeoutId)
    if (this.props.lazy) {
      this.el.current.removeEventListener(
        "lazybeforeunveil",
        this.onLazybeforeunveil.bind(this)
      )
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false
    const diff = this.diff(nextState, this.state)
    if (diff("playing") || diff("ready")) {
      this._togglePlay(nextState.playing && nextState.ready)
      shouldUpdate = true
    }
    if (diff("preloaded")) {
      shouldUpdate = true
    }
    if (diff("hasPlayed")) {
      shouldUpdate = true
    }
    if (diff("lazyReady")) {
      shouldUpdate = true
    }
    if (diff("muted")) {
      shouldUpdate = true
    }
    return shouldUpdate
  }

  attrs() {
    return {
      autoPlay: this.props.autoPlay,
      muted: this.state.muted,
    }
  }

  stateClassName() {
    let className = ""
    if (this.props.cover) {
      className += " has-cover"
    }
    className += this.state.ready ? " is-ready" : ""
    className += this.state.preloaded ? " is-preloaded" : ""
    className += this.state.hasPlayed ? " has-played" : ""
    return className
  }

  onLazybeforeunveil() {
    this.setState({ lazyReady: true })
  }

  render() {
    const { autoPlay } = this.props
    const hasBlur = typeof this.props.blur !== "undefined"
    const hasVideo = !isMobile() || this.props.mobileVideo
    const src =
      !this.props.lazy || this.state.lazyReady ? { src: this.props.src } : {}

    return (
      <PreviewsContext.Consumer>
        {({ onToggleSound, onMouseEnterPreview }) => {
          return (
            <div
              className={`preview ${
                this.props.className
              } ${this.stateClassName()} ${this.props.lazy && "lazyload"}`}
              role="presentation"
              onMouseEnter={() => {
                this.onMouseEnter()
                !autoPlay && onMouseEnterPreview(this)
              }}
              ref={this.el}
            >
              <ObjectFit
                className="preview__bg h-full"
                ref={this.objectFit}
                size={this.props.size || "cover"}
              >
                {hasVideo ? (
                  <video
                    {...src}
                    loop
                    playsInline
                    ref={this.video}
                    onLoadedMetadata={this.onLoadedMetaData}
                    onLoadedData={this.onLoadedData}
                    onCanPlay={this.onCanPlay}
                    {...this.attrs()}
                    preload={this.props.preload}
                    autoPlay={this.props.autoPlay}
                  ></video>
                ) : (
                  ""
                )}
                {this.props.hasSound ? (
                  <div
                    className="btn-sound"
                    onClick={() => {
                      this.onClickSound()
                      onToggleSound(this)
                    }}
                  >
                    {this.state.muted ? (
                      <svg
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m21.99 10.75.7.7 2.1 2.13 2.8-2.83L29 12.16l-.7.71L26.2 15l2.1 2.12.7.7-1.4 1.42-.7-.7-2.1-2.13-2.1 2.12-.71.7-1.4-1.4.7-.71 2.1-2.12-2.8-2.83 1.4-1.4Z"
                          fill="#fff"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 11a3 3 0 0 1 3-3h3.72l9.77-5.86A1 1 0 0 1 18 3v23.98a1 1 0 0 1-1.51.86l-9.77-5.86H3a3 3 0 0 1-3-3m7.28 1 .23.15L16 25.2V4.77L7.52 9.85 7 9l.52.86-.24.14H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.28Z"
                          fill="#fff"
                        />
                      </svg>
                    ) : (
                      <svg
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m7 20.99.51-.86-.23-.14H7v1Zm0-12v1h.28l.24-.14L7 9ZM17 3h1a1 1 0 0 0-1.51-.86L17 3Zm0 23.98-.51.86a1 1 0 0 0 1.51-.86h-1ZM7 20H3v2h4v-2Zm-4 0a1 1 0 0 1-1-1H0a3 3 0 0 0 3 3v-2Zm-1-1v-8H0v8h2Zm0-8a1 1 0 0 1 1-1V8a3 3 0 0 0-3 3h2Zm1-1h4V8H3v2Zm4.52-.14 10-6-1.03-1.7-10 5.99 1.03 1.71ZM16 3v23.98h2V3h-2Zm1.51 23.13-10-6-1.02 1.72 10 6 1.02-1.72Z"
                          fill="#fff"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.65 15c0-.74-.6-1.35-1.35-1.35v-2.1a3.45 3.45 0 1 1 0 6.9v-2.1c.74 0 1.35-.6 1.35-1.35Z"
                          fill="#fff"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M26.85 15a5.55 5.55 0 0 0-5.55-5.55v-2.1a7.65 7.65 0 1 1 0 15.3v-2.1A5.55 5.55 0 0 0 26.85 15Z"
                          fill="#fff"
                        />
                      </svg>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="preview__cover"
                  style={{ backgroundImage: `url(${this.props.cover})` }}
                ></div>
                {hasBlur ? (
                  <div
                    className="preview__blur"
                    style={{ backgroundImage: `url(${this.props.blur})` }}
                  ></div>
                ) : (
                  ""
                )}
              </ObjectFit>

              <div className="preview__title">
                <div>{this.props.children}</div>
              </div>
            </div>
          )
        }}
      </PreviewsContext.Consumer>
    )
  }
}

Preview.defaultProps = {
  preload: "",
}

export default Preview
