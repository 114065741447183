const initialState = {
  showInfo: false,
  showProjects: false,
  projectIndex: 0,
  projectScroll: 0,
  showCredits: false,
  showIntro: false,
  location: null,
  project: null,
  hasProtectedAccess: null,
  rotationMultiplier: 1,
  rotationSource: "scroll",
}

// generate it with console function btoa(password_here) here: THEMING2020
const Nicfok91 = "VEhFTUlORzIwMjA="

const TOGGLE_INFO = "TOGGLE_INFO"
const TOGGLE_PROJECTS = "TOGGLE_PROJECTS"
const SET_PROJECTINDEX = "SET_PROJECTINDEX"
const SET_PROJECTSCROLL = "SET_PROJECTSCROLL"
const TOGGLE_CREDITS = "TOGGLE_CREDITS"
const SAVE_LOCATION = "SAVE_LOCATION"
const TOGGLE_INTRO = "TOGGLE_INTRO"
const SET_PROJECT = "SET_PROJECT"
const TRY_PASSWORD = "TRY_PASSWORD"
const SET_ROTATION_MULTIPLIER = "SET_ROTATION_MULTIPLIER"
const SET_ROTATION_SOURCE = "SET_ROTATION_SOURCE"

export const toggleInfo = (showInfo) => ({
  type: TOGGLE_INFO,
  showInfo,
})

export const toggleProjects = (showProjects) => ({
  type: TOGGLE_PROJECTS,
  showProjects,
})

export const setProjectIndex = (projectIndex) => ({
  type: SET_PROJECTINDEX,
  projectIndex,
})

export const setProjectScroll = (projectScroll) => ({
  type: SET_PROJECTSCROLL,
  projectScroll,
})

export const toggleCredits = (showCredits) => ({
  type: TOGGLE_CREDITS,
  showCredits,
})

export const saveLocation = (location) => ({
  type: SAVE_LOCATION,
  location,
})

export const toggleIntro = (showIntro) => ({
  type: TOGGLE_INTRO,
  showIntro,
})

export const setProject = (project) => ({
  type: SET_PROJECT,
  project,
})

export const tryPassword = (p) => ({
  type: TRY_PASSWORD,
  p,
})

export const setRotationMultiplier = (rotationMultiplier) => ({
  type: SET_ROTATION_MULTIPLIER,
  rotationMultiplier,
})

export const setRotationSource = (rotationSource) => ({
  type: SET_ROTATION_SOURCE,
  rotationSource,
})
export const setRotationSourceTouch = () => ({
  type: SET_ROTATION_SOURCE,
  rotationSource: "touch",
})
export const setRotationSourceScroll = () => ({
  type: SET_ROTATION_SOURCE,
  rotationSource: "scroll",
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_INFO:
      return { ...state, showInfo: action.showInfo }
    case TOGGLE_PROJECTS:
      return { ...state, showProjects: action.showProjects }
    case SET_PROJECTINDEX:
      return { ...state, projectIndex: action.projectIndex }
    case SET_PROJECTSCROLL:
      return { ...state, projectScroll: action.projectScroll }
    case TOGGLE_CREDITS:
      return { ...state, showCredits: action.showCredits }
    case SAVE_LOCATION:
      return { ...state, location: action.location }
    case TOGGLE_INTRO:
      return { ...state, showIntro: action.showIntro }
    case SET_PROJECT:
      return { ...state, project: action.project }
    case TRY_PASSWORD:
      return { ...state, hasProtectedAccess: action.p === atob(Nicfok91) }
    case SET_ROTATION_MULTIPLIER:
      return { ...state, rotationMultiplier: action.rotationMultiplier }
    case SET_ROTATION_SOURCE:
      return { ...state, rotationSource: action.rotationSource }
    default:
      return state
  }
}
