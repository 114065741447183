import React from 'react'

const Modal = ({ className, children, onClick }) => {

  return (
    <div className={`modal ${className}`}>
      <div className="modal__wrapper">
        <div className="modal__inner">
          <div className="modal__spacer"></div>
          <div className="modal__content" role="button" onClick={onClick}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal