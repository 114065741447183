const cp = `
float cp(vec2 uv) {
    float y = step(1.0 - uv.y, 1.0 - uClip);
    float x1 = 1.0 - step(uMargin, uv.x);
    float x2 = 1.0 - step(uMargin, 1.0 - uv.x);
    return max(y, max(x1, x2));
}
`

const fragmentPars = `
    precision highp float;

    uniform float uClip;
    uniform float uMargin;

    varying vec4 vTexCoords;

    ${cp}
`

const fragment = `
    vec2 st = (vTexCoords.xy / vTexCoords.w) * 0.5 + 0.5;
    gl_FragColor.a = cp(st);
`

const fragmentPars300 = `
    precision highp float;

    uniform float uClip;
    uniform float uMargin;

    in vec4 vTexCoords;

    ${cp}
`

const fragment300 = `
    vec2 st = (vTexCoords.xy / vTexCoords.w) * 0.5 + 0.5;
    color.a = cp(st);
`

const uniforms = {
  uClip: { value: 0 },
  uMargin: { value: 0 },
}

export { fragmentPars, fragmentPars300, fragment, fragment300, uniforms }
