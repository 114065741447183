import React, { useEffect } from "react"
import { TransitionState } from "gatsby-plugin-transition-link"
import { connect } from "react-redux"

import { SEO, ThirdDimension } from "./index"

import "../styles/main.styl"
import { saveLocation } from "../state/store"

const Layout = ({ hasOverlay, dispatch, location, children }) => {
  const { title } = children.props.pageContext

  useEffect(() => {
    dispatch(saveLocation(location.pathname))
  })

  const divStyle = {
    visibility: "hidden",
  }

  return (
    <div className={`layout ${hasOverlay ? "has-overlay" : ""}`} id="app">
      <SEO pathname={location.pathname} title={title} />
      <ThirdDimension />
      <div className={`layout__content`} style={divStyle}>
        <TransitionState>{() => children}</TransitionState>
      </div>
    </div>
  )
}

export default React.memo(
  connect(
    (state) => ({
      hasOverlay: state.showProjects || state.showInfo || state.showCredits,
    }),
    null
  )(Layout)
)
