import React from "react"

export const PreviewsContext = React.createContext()

class Previews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onMouseEnterPreview: this.play.bind(this),
      onToggleSound: this.toggleSound.bind(this),
    }
  }
  play(preview, force = false) {
    if (this.active && this.active.uId !== preview.uId) {
      this.active.pause()
    }
    this.active = preview
    if (force) this.active.play()
  }
  toggleSound(preview) {
    if (this.activeSound && this.activeSound.uId !== preview.uId) {
      this.activeSound.mute()
    }
    this.activeSound = preview
  }
  render() {
    return (
      <PreviewsContext.Provider value={this.state}>
        {this.props.children}
      </PreviewsContext.Provider>
    )
  }
}

export default Previews
