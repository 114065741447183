import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import { connect } from "react-redux"
import { useSprings, animated, config } from "react-spring"
import { useDrag } from "react-use-gesture"
import { clamp } from "lodash"
import { useDebouncedCallback } from "use-debounce"

import { Fade } from "../components"
import { Preview, Previews } from "./index"
import { useWindowSize } from "../../utils/hooks"
import {
  toggleProjects,
  setProjectIndex,
  setProjectScroll,
} from "../state/store"
import { hasHover } from "../../utils/device"

const mod = function (a, b) {
  // Calculate
  return ((a % b) + b) % b
}

const Projects = ({
  projectIndex,
  projectScroll,
  showProjects,
  projects,
  dispatch,
}) => {
  const projectsRef = useRef([])
  const previewsRef = useRef()
  const previewRef = useRef([])
  const title = useRef()
  const index = useRef(0)
  const scrollTarget = useRef(projectScroll)
  const scrollValue = useRef(projectScroll)
  const rafId = useRef()
  const VS = useRef()

  const isDragging = useRef(false)

  const { width } = useWindowSize()
  const itemWidth = width < 768 ? width * 0.888 : width * 0.416

  const offset =
    width * 0.5 -
    (projects.length % 2 === 0 || !hasHover() ? itemWidth * 0.5 : 0)

  // set initial position, after getting size
  const [springs, set] = useSprings(
    projects.length,
    (i) => ({ x: (i - projectIndex) * itemWidth + offset, display: "block" }),
    [width, itemWidth, offset]
  )

  const onScrollEnd = useDebouncedCallback(function () {
    dispatch(setProjectScroll(scrollTarget.current))
  }, 150)

  useLayoutEffect(() => {
    if (
      !hasHover() ||
      !showProjects ||
      set === undefined ||
      width === undefined
    )
      return
    if (rafId.current) cancelAnimationFrame(rafId.current)

    const update = function () {
      const val = (scrollTarget.current - scrollValue.current) * 0.16
      scrollValue.current = val

      set((i) => {
        const x =
          (i + Math.ceil((projects.length - width / itemWidth) * 0.5)) *
            itemWidth +
          val +
          offset
        const modX =
          mod(x, itemWidth * projects.length) -
          itemWidth * projects.length * 0.5
        return {
          x: modX,
          display: "block",
          config: { friction: 0, tension: 0 },
        }
      })

      requestAnimationFrame(update)
    }

    const onScroll = (e) => {
      scrollTarget.current = e.y
      onScrollEnd.callback(e)
    }

    const VirtualScroll = require("virtual-scroll")
    VS.current = new VirtualScroll({
      touchMultiplier: 1,
    })
    setTimeout(() => {
      if (VS.current._event) VS.current._event.y = scrollTarget.current
    }, 100)

    VS.current.on(onScroll, this)

    rafId.current = requestAnimationFrame(update)

    return () => {
      if (VS.current) {
        VS.current.off(onScroll, this)
        VS.current.destroy()
      }
      if (rafId.current) cancelAnimationFrame(rafId.current)
    }
  }, [set, width, showProjects])

  let bind = useDrag(
    ({
      active,
      last,
      tap,
      movement: [xMovement],
      direction: [xDir],
      distance,
      cancel,
      canceled,
    }) => {
      if (hasHover()) return

      isDragging.current = !tap
      if (tap) return

      if (!active && last) {
        cancel(
          (index.current = clamp(
            projectIndex + (xDir > 0 ? -1 : 1),
            0,
            projects.length - 1
          ))
        )
        dispatch(setProjectIndex(index.current))
        previewsRef.current.play(previewRef.current[index.current], true)
      }

      set((i) => {
        if (i < projectIndex - 2 || i > projectIndex + 2)
          return { display: "none" }
        const x =
          (i - projectIndex) * itemWidth + (active ? xMovement : 0) + offset
        return { x, display: "block" }
      })
    }
  )

  const onMouseDownFade = (e) => {
    // allow dragging link without triggering chrome
    e.preventDefault()
  }

  const onClick = (e, i) => {
    // disable link if dragging
    dispatch(toggleProjects(false))
    if (isDragging.current) e.preventDefault()
  }

  const onMouseEnter = (e, i) => {
    if (!hasHover()) return
    projectsRef.current.forEach((el, fi) => {
      const isActive = fi === i
      el.classList.toggle("-is-active", isActive)
      if (isActive)
        title.current.innerText = projects[fi].node.frontmatter.title
    })
  }

  return (
    <div className={`projects`}>
      <div className="projects__track">
        <Previews ref={previewsRef}>
          {springs.map(({ x, display, sc }, i) => (
            <animated.div
              ref={(el) => (projectsRef.current[i] = el)}
              className={`projects__item ${i} ${
                projectIndex === i ? "-is-active" : ""
              }`}
              {...bind()}
              key={i}
              style={{ display, x }}
            >
              <Fade
                className="block"
                to={projects[i].node.frontmatter.slug}
                onMouseDown={onMouseDownFade}
                onClick={(e) => {
                  onClick(e, i)
                }}
              >
                <div
                  className="projects__picture"
                  onMouseEnter={(e) => {
                    onMouseEnter(e, i)
                  }}
                >
                  <Preview
                    className="projects__video"
                    cover={`/content/${projects[i].node.frontmatter.slug}/thumbnail.jpg`}
                    src={projects[i].node.frontmatter.video_thumbnail}
                    ref={(el) => (previewRef.current[i] = el)}
                  />
                </div>
              </Fade>
            </animated.div>
          ))}
        </Previews>
      </div>
      <div className="projects__title" ref={title}>
        {projects[projectIndex].node.frontmatter.title}
      </div>
    </div>
  )
}

export default connect(
  (state) => ({
    projectIndex: state.projectIndex,
    projectScroll: state.projectScroll,
    showProjects: state.showProjects,
  }),
  null
)(Projects)
