import { Geometry, Program, Mesh, Text } from "ogl"
import loadTexture from "./load-texture"
import * as clippingPlane from "../src/components/third-dimension/clipping-plane"

const vertex100 = /* glsl */ `
    precision highp int;
    attribute vec2 uv;
    attribute vec3 position;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    varying vec2 vUv;
    varying vec4 vTexCoords;
    void main() {
        vUv = uv;
        
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vTexCoords = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`

const fragment100 = /* glsl */ `
    #extension GL_OES_standard_derivatives : enable
    precision highp int;
    ${clippingPlane.fragmentPars}
    uniform sampler2D tMap;
    uniform float hover;
    varying vec2 vUv;
    
    vec3 blue = vec3(66., 0., 255.) / 255.;

    void main() {
        vec3 tex = texture2D(tMap, vUv).rgb;
        float signedDist = max(min(tex.r, tex.g), min(max(tex.r, tex.g), tex.b)) - 0.5;
        float d = fwidth(signedDist);
        float alpha = smoothstep(-d, d, signedDist);
        if (alpha < 0.01) discard;
        gl_FragColor.rgb = mix(vec3(0.0), blue, hover);
        gl_FragColor.a = alpha;

        ${clippingPlane.fragment}
    }
`

const vertex300 = /* glsl */ `#version 300 es
    precision highp int;
    in vec2 uv;
    in vec3 position;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    out vec2 vUv;
    out vec4 vTexCoords;
    void main() {
        vUv = uv;
        
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vTexCoords = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`

const fragment300 = /* glsl */ `#version 300 es
    precision highp int;
    ${clippingPlane.fragmentPars300}
    uniform sampler2D tMap;
    uniform float hover;
    in vec2 vUv;
    out vec4 color;

    vec3 blue = vec3(66., 0., 255.) / 255.;

    void main() {
        vec3 tex = texture(tMap, vUv).rgb;
        float signedDist = max(min(tex.r, tex.g), min(max(tex.r, tex.g), tex.b)) - 0.5;
        float d = fwidth(signedDist);
        float alpha = smoothstep(-d, d, signedDist);
        if (alpha < 0.01) discard;
        color.rgb = mix(vec3(0.0), blue, hover);
        color.a = alpha;

        ${clippingPlane.fragment300}
    }
`

const loadFont = async (gl) => {
  const texture = await loadTexture(gl, "/msdf/Plaid-S2.png")
  const json = await (await fetch("/msdf/Plaid-S2-msdf.json")).json()

  return [texture, json]
}

let program
let font
let tex
const createProgram = async (gl, renderer) => {
  if (program) {
    console.warn("MSDF program already created")
    return Promise.reject()
  }

  const [texture, json] = await loadFont(gl)
  font = json
  tex = texture
  return Promise.resolve()
}

let id = 1
function createText(
  gl,
  {
    text = "insert text",
    width = 4,
    align = "center",
    letterSpacing = 0,
    size = 1,
    lineHeight = 1.1,
  } = {}
) {
  if (!font) {
    console.warn("font not loaded, call createProgram() first")
    return
  }

  const msdfText = new Text({
    font,
    text,
    width,
    align,
    letterSpacing,
    size,
    lineHeight,
  })

  // Pass the generated buffers into a geometry
  const geometry = new Geometry(gl, {
    position: { size: 3, data: msdfText.buffers.position },
    uv: { size: 2, data: msdfText.buffers.uv },
    id: { size: 1, data: msdfText.buffers.id },
    index: { data: msdfText.buffers.index },
  })
  id++

  const uniforms = {
    tMap: { value: tex },
    hover: { value: 0 },
  }
  Object.entries(clippingPlane.uniforms).forEach(([key, value]) => {
    uniforms[key] = value
  })

  const program = new Program(gl, {
    // Get fallback shader for WebGL1 - needed for OES_standard_derivatives ext
    vertex: gl.renderer.isWebgl2 ? vertex300 : vertex100,
    fragment: gl.renderer.isWebgl2 ? fragment300 : fragment100,
    uniforms,
    cullFace: null,
  })

  const mesh = new Mesh(gl, { geometry, program })

  // Use the height value to position text vertically. Here it is centered.
  mesh.position.y = msdfText.height * 0.5

  return mesh
}

export { createProgram, loadFont, createText }
