import React from 'react'

import { HoverContext } from './hover-group'

class HoverItem extends React.Component {
  render() {
    return (
      <HoverContext.Consumer>
        {
          ({ current, onMouseEnter }) => {
            return (
              <div className={`hover-item ${(current !== false && current !== this.props.id) ? 'is-out' : ''}`} onMouseEnter={onMouseEnter(this.props.id)}>
                {this.props.children}
              </div>
            )
          }
        }
      </HoverContext.Consumer>
    )
  }
}

export default HoverItem
