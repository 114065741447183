import { Texture } from "ogl"

const loadTexture = (glContext, url) => {
  return new Promise((resolve) => {
    const texture = new Texture(glContext, {
      generateMipmaps: false,
    })
    const img = new Image()
    img.onload = () => {
      texture.image = img
      resolve(texture)
    }
    img.src = url
  })
}

export default loadTexture
