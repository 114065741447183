import gatsbyPluginTransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"
import { Box, Program, Mesh, Transform } from "ogl"
import { createText } from "../../../utils/msdf"
import * as clippingPlane from "./clipping-plane"
import { modulo, map, delay, slugify, getScreenSize } from "../../../utils"

export default class Cube {
  constructor({ gl, parent, page, projects } = {}) {
    this._page = page
    this._started = false
    this._startAnimationComplete = false
    this._projects = projects
    this.projectPageOffset = {
      x: 0,
      y: 0,
      rotation: 0,
    }

    this.objectOffset = {
      x: 0,
      y: 0,
    }

    this._rotation = {
      tick: 0,
      direction: -1,
      scroll: 0,
      tween: Math.PI,
    }
    this._hover = false
    this._scale = {
      value: 1,
      target: 1,
      transform: 1,
      default: 1,
      intro: 2,
    }
    this._labelSwapTimeout = null
    this._labelsNeedUpdate = {
      main: false,
      side: false,
    }

    this._dimensions = {
      width: 3,
      height: 1.7,
      depth: 0.35,
    }

    // this.init(gl, parent)
  }

  /* --------------------------
    INIT
  --------------------------- */

  async init(gl, parent) {
    const geometry = new Box(gl, this._dimensions)

    this.uniforms = Object.assign(clippingPlane.uniforms, {
      uBrightness: { value: 1 },
    })

    const program = new Program(gl, {
      vertex: `
        attribute vec3 position;

        uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;

        varying vec4 vTexCoords;

        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            vTexCoords = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }`,

      fragment: `
        ${clippingPlane.fragmentPars}
        uniform float uBrightness;

        void main() {
            gl_FragColor = vec4(1.0);

            ${clippingPlane.fragment}

            gl_FragColor.rgb *= uBrightness;
        }`,
      uniforms: this.uniforms,
      transparent: true,
    })

    this.mesh = new Mesh(gl, { geometry, program })
    this.object = new Transform()
    this.object.scale.x = 0
    this.object.setParent(parent)
    this.mesh.setParent(this.object)

    await this.initLabels(gl)
    return this
  }

  async initLabels(gl) {
    this.info = this.createTextLabel(gl, { text: "INFO", side: "front" })
    this.work = this.createTextLabel(gl, { text: "WORK", side: "back" })
    this.credits = this.createTextLabel(gl, { text: "CREDITS", side: "back" })
    this.close = this.createTextLabel(gl, { text: "CLOSE", side: "front" })
    this.previous = this.createTextLabel(gl, {
      text: "PREVIOUS PROJECT",
      side: "bottom",
    })
    this.next = this.createTextLabel(gl, { text: "NEXT PROJECT", side: "top" })
    this.projects1 = this.createTextLabel(gl, {
      text: this._projects
        .slice(0, 2)
        .map((p) => p.node.frontmatter.title.toUpperCase()),
      side: "bottom",
      slug: this._projects.slice(0, 2).map((p) => p.node.frontmatter.slug),
    })
    this.projects2 = this.createTextLabel(gl, {
      text: this._projects
        .slice(2, 4)
        .map((p) => p.node.frontmatter.title.toUpperCase()),
      side: "top",
      slug: this._projects.slice(2, 4).map((p) => p.node.frontmatter.slug),
    })

    this.hitBoxes = [
      this.info,
      this.work,
      this.credits,
      this.close,
      this.previous,
      this.next,
      ...this.projects1.children,
      ...this.projects2.children,
    ]

    await delay(200)

    const labels = [
      this.info,
      this.work,
      this.credits,
      this.close,
      this.previous,
      this.next,
      this.projects1,
      this.projects2,
    ]

    this.mesh.geometry.computeBoundingSphere()

    labels.forEach((mesh) => {
      this.setMeshPosition(mesh)
    })

    if (this._page === "/") {
      this.credits.visible = false
      this.close.visible = false
      this.previous.visible = false
      this.next.visible = false
    } else {
      this.work.visible = false
      this.info.visible = false
      this.projects1.visible = false
      this.projects2.visible = false
    }
  }

  /* --------------------------
    METHODS
  --------------------------- */

  createTextLabel(gl, { text, name, side, slug } = {}) {
    let mesh
    if (Array.isArray(text)) {
      mesh = new Transform()
      text.forEach((name, i) => {
        const textMesh = createText(gl, {
          text: name,
          letterSpacing: -0.1,
          size: side === "bottom" || side === "top" ? 0.35 : 1,
          width: 5,
        })
        textMesh.setParent(mesh)
        textMesh.name = slug[i] || slugify(name)
        textMesh.geometry.computeBoundingSphere()
      })
    } else {
      mesh = createText(gl, {
        text,
        letterSpacing: -0.1,
        size: side === "bottom" || side === "top" ? 0.25 : 1,
      })
      mesh.name = slug || name || slugify(text)
      mesh.geometry.computeBoundingSphere()
    }
    mesh.setParent(this.mesh)

    switch (side) {
      case "front":
        mesh.position.z = this._dimensions.depth * 0.5 + 0.0001
        break
      case "back":
        mesh.rotation.x = Math.PI
        mesh.position.z = -(this._dimensions.depth * 0.5 + 0.0001)
        break
      case "bottom":
        mesh.rotation.x = Math.PI * 0.5
        mesh.position.y = -(this._dimensions.height * 0.5 + 0.0001)
        mesh.position.z = 0.13
        break
      case "top":
        mesh.rotation.x = Math.PI * -0.5
        mesh.position.y = this._dimensions.height * 0.5 + 0.0001
        mesh.position.z = -0.13
        break
    }
    mesh.customData = {
      side,
    }
    return mesh
  }

  setMeshPosition(mesh) {
    let x = 0
    let y = 1
    let gap = 0

    if (mesh.children.length) {
      gap =
        (this.projects1.children[0].geometry.bounds.scale[0] +
          this.projects1.children[1].geometry.bounds.scale[0]) *
        0.05
      x =
        this._dimensions.width /
        (mesh.children[0].geometry.bounds.scale[0] +
          mesh.children[1].geometry.bounds.scale[0] +
          gap * 1.5)
    } else {
      x = this._dimensions.width / mesh.geometry.bounds.scale[0]
    }

    switch (mesh.customData.side) {
      case "front":
        y = this._dimensions.height / mesh.geometry.bounds.scale[1]
        mesh.position.y += (y - 1) / 2 - 0.01
        mesh.position.x -= 0.05
        mesh.position.z += 0.001
        break
      case "back":
        y = this._dimensions.height / mesh.geometry.bounds.scale[1]
        mesh.position.y *= -1
        mesh.position.y -= (y - 1) / 2 - 0.01
        mesh.position.x -= 0.025
        mesh.position.z -= 0.001
        break
      case "top":
        mesh.position.x += mesh.children.length ? 0 : -0.02
        mesh.position.y += 0.001
        mesh.position.z = mesh.children.length ? 0.01 : mesh.position.z
        break
      case "bottom":
        mesh.position.x += mesh.children.length ? -0.01 : -0.02
        mesh.position.y -= 0.001
        mesh.position.z = mesh.children.length ? -0.01 : mesh.position.z
        break
    }

    // if (mesh.name === "previous-project" || mesh.name === "next-project") {
    x *= 0.97
    // }

    mesh.scale.set(x, y, 1)

    if (mesh.children.length) {
      mesh.children[0].position.x =
        (mesh.children[1].geometry.bounds.scale[0] + gap) * -0.5
      mesh.children[1].position.x =
        (mesh.children[0].geometry.bounds.scale[0] + gap) * 0.5
    }
  }

  update({ modalVisible } = {}) {
    if (!this._started) return

    if (!modalVisible) this._rotation.tick += 0.002 * this._rotation.direction
    this._rotation.tick = modulo(this._rotation.tick, Math.PI * 2)
    this._rotation.scroll = modulo(this._rotation.scroll, Math.PI * 2)
    this.mesh.rotation.x =
      this._rotation.tick + this._rotation.scroll + this._rotation.tween

    this._scale.value +=
      (this._scale.target * this._scale.transform - this._scale.value) * 0.1

    if (this._startAnimationComplete) {
      this.mesh.scale.set(this._scale.value)
    } else {
      this.mesh.scale.set(this._scale.intro)
    }

    if (
      this._labelsNeedUpdate.main &&
      Math.abs(modulo(this.mesh.rotation.x, Math.PI) - Math.PI * 0.5) <= 0.1
    ) {
      this.updateMainLabels(this._page)
    }
    if (
      this._labelsNeedUpdate.side &&
      Math.abs(modulo(this.mesh.rotation.x, Math.PI)) <= 0.1
    ) {
      this.updateSideLabels(this._page)
    }
  }

  async resize({ width, height, camera }) {
    const screenSize = getScreenSize({
      fov: camera.fov,
      aspect: camera.aspect,
      dist: camera.position.z + this.mesh.position.z * -1,
    })

    this._scale.default =
      Math.min(1, screenSize.width / screenSize.height) * 1.75

    if (!this._started) {
      if (window.innerWidth < window.innerHeight) {
        this.object.rotation.z = Math.PI * 0.5
        this._scale.intro =
          screenSize.width / screenSize.height >
          this._dimensions.width / this._dimensions.height
            ? screenSize.height * (1 / this._dimensions.width)
            : screenSize.width * (1 / this._dimensions.height)
      } else {
        this._scale.intro =
          screenSize.width / screenSize.height >
          this._dimensions.width / this._dimensions.height
            ? screenSize.width * (1 / this._dimensions.width)
            : screenSize.height * (1 / this._dimensions.height)
      }
      this.mesh.scale.set(this._scale.intro)
    }
    this._scale.target = this._scale.default

    // if (!this.mesh.geometry.bounds) {
    //   await delay(100)
    // }

    this.objectOffset = {
      x: width < height ? 0 : 0.5,
      y: width < height ? -0.75 : -0.5,
    }
    if (this._started) {
      const x =
        this._page === "/" ? this.objectOffset.x : this.projectPageOffset.x
      const y =
        this._page === "/" ? this.objectOffset.y : this.projectPageOffset.y
      this.object.position.set(x, y, 0)
    }

    this.projectPageOffset.x = Math.max(
      0,
      screenSize.width * 0.5 - this._dimensions.width * 0.5
    )
    this.projectPageOffset.y =
      (screenSize.height - this._dimensions.height * this._scale.default) *
      (width < height ? -0.5 : -0.75)
    this.projectPageOffset.rotation = map(
      this.projectPageOffset.x,
      0,
      2,
      0,
      -0.5
    )

    if (this._page !== "/") {
      this._scale.transform = 0.65 * (1 / this._scale.default)
      if (this._started)
        this.object.rotation.y = this.projectPageOffset.rotation
    }
  }

  updateMainLabels(page) {
    page = page ? page : this._page

    switch (page) {
      case "/":
        this.work.visible = true
        this.info.visible = true
        this.credits.visible = false
        this.close.visible = false
        break
      default:
        this.work.visible = false
        this.info.visible = false
        this.credits.visible = true
        this.close.visible = true
        break
    }

    this._labelsNeedUpdate.main = false
  }

  updateSideLabels(page) {
    page = page ? page : this._page

    switch (page) {
      case "/":
        this.previous.visible = false
        this.next.visible = false
        this.projects1.visible = true
        this.projects2.visible = true
        ;[...this.projects1.children, ...this.projects2.children].forEach(
          (mesh) => {
            mesh.visible = true
          }
        )
        break
      default:
        this.previous.visible = true
        this.next.visible = true
        this.projects1.visible = false
        this.projects2.visible = false
        ;[...this.projects1.children, ...this.projects2.children].forEach(
          (mesh) => {
            mesh.visible = false
          }
        )
        break
    }

    this._labelsNeedUpdate.side = false
  }

  transitionToPage(page) {
    gsap.to(this._rotation, {
      tween: this._rotation.tween + Math.PI * 2 * this._rotation.direction,
      duration: 1,
      ease: "cubic.inOut",
    })

    gsap.to(this.object.position, {
      x: this._page === "/" ? this.objectOffset.x : this.projectPageOffset.x,
      y: this._page === "/" ? this.objectOffset.y : this.projectPageOffset.y,
      duration: 1,
      ease: "cubic.inOut",
    })
    gsap.to(this.object.rotation, {
      y: page === "/" ? 0 : this.projectPageOffset.rotation,
      z: page === "/" ? 0.3 : -0.1,
      duration: 1,
      ease: "cubic.inOut",
    })
    gsap.to(this._scale, {
      transform: page === "/" ? 1 : 0.65 * (1 / this._scale.default),
      duration: 1,
      ease: "cubic.inOut",
    })
  }

  animateIn() {
    return new Promise((resolve) => {
      gsap.to(this.object.scale, {
        x: 1,
        duration: 1,
        ease: "cubic.inOut",
        onComplete: resolve,
      })
    })
  }

  start() {
    return new Promise((resolve) => {
      this._scale.target = this._scale.default
      this._started = true

      gsap.to(this.object.rotation, {
        y: this._page === "/" ? 0 : this.projectPageOffset.rotation,
        z: this._page === "/" ? 0.3 : -0.1,
        duration: 1.5,
        ease: "sine.inOut",
      })
      gsap.to(this.object.position, {
        x: this._page === "/" ? this.objectOffset.x : this.projectPageOffset.x,
        y: this._page === "/" ? this.objectOffset.y : this.projectPageOffset.y,
        duration: 1.5,
        ease: "sine.inOut",
      })

      gsap.to(this._rotation, {
        tween: this._rotation.tween + Math.PI * 2 * this._rotation.direction,
        duration: 1.5,
        ease: "cubic.inOut",
      })

      gsap.to(this._scale, {
        intro: this._scale.target * this._scale.transform,
        duration: 1.5,
        ease: "cubic.inOut",
        onComplete: () => {
          this._startAnimationComplete = true
          resolve()
        },
      })
    })
  }

  /* --------------------------
    GETTERS SETTERS
  --------------------------- */

  set brightness(b) {
    if (this.uniforms) this.uniforms.uBrightness.value = b
  }

  get brightness() {
    return this.uniforms ? this.uniforms.uBrightness.value : 1
  }

  set page(page) {
    this._page = page

    this.transitionToPage(page)

    this._labelsNeedUpdate.main = true
    this._labelsNeedUpdate.side = true
  }

  set hover(hover) {
    if (!this._started) return
    if (this._hover === hover) return

    if (this._hover) {
      gsap.killTweensOf(this._hover.program.uniforms.hover)
      gsap.to(this._hover.program.uniforms.hover, {
        value: 0,
        duration: 0.2,
        ease: "cubic.out",
      })
    }
    if (hover) {
      gsap.killTweensOf(hover.program.uniforms.hover)
      gsap.to(hover.program.uniforms.hover, {
        value: 1,
        duration: 0.2,
        ease: "cubic.out",
      })
    }

    this._hover = hover
    this._scale.target = hover ? this._scale.default * 1.1 : this._scale.default
  }
}
