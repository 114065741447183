import gsap from "gsap"
import { Program, Mesh, Transform, Sphere } from "ogl"
import { loadGLTF, loadTexture } from "../../../utils"

export default class Cube {
  constructor({ gl, parent } = {}) {
    this._visible = false
    this._hover = false
    this._scale = {
      value: 1,
      target: 1,
      transform: 1,
      default: 1,
    }
    this.init(gl, parent)
  }

  async init(gl, parent) {
    const cross = await loadGLTF(gl, "/gltf/cross.glb")
    const texture = await loadTexture(gl, "/gltf/Close-UV-Map_copy.jpg")
    const geometry = cross.children[0].geometry

    texture.wrapS = gl.REPEAT
    texture.wrapT = gl.REPEAT

    this.uniforms = {
      opacity: { value: 0 },
      uMap: { value: texture },
      uTime: { value: 0 },
    }

    const program = new Program(gl, {
      vertex: `
        attribute vec3 position;
        attribute vec2 uv;

        uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;
        uniform float opacity;

        varying vec2 vUv;

        void main() {
          vUv = uv;
          vec3 transform = position;
          transform *= opacity * 0.2 + 0.8;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(transform, 1.0);
        }`,

      fragment: `
        precision highp float;
        uniform float opacity;
        uniform sampler2D uMap;
        uniform float uTime;

        varying vec2 vUv;

        void main() {
          vec2 uv = vec2(vUv.x-uTime, 1.0-vUv.y);
            vec3 color = texture2D(uMap, uv).rgb;
            gl_FragColor = vec4(color, opacity);
        }`,
      // depthTest: false,
      // depthWrite: false,
      uniforms: this.uniforms,
      transparent: true,
    })

    this.object = new Transform()
    this.object.setParent(parent)

    this.mesh = new Mesh(gl, { geometry, program })
    this.mesh.name = "cross"
    this.mesh.visible = false
    this.mesh.setParent(this.object)

    this.object.scale.set(0.05, 0.05, 0.05)
    this.object.position.y = 0.6
    this.object.position.z = 2

    const hitboxProgram = new Program(gl, {
      vertex: `
        attribute vec3 position;

        uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;

        void main() {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }`,

      fragment: `
        precision highp float;

        void main() {
            gl_FragColor = vec4(1.0, 1.0, 1.0, 0.0);
        }`,
      transparent: true,
      depthWrite: false,
    })
    this.hitbox = new Mesh(gl, {
      geometry: new Sphere(gl, { radius: 8 }),
      program: hitboxProgram,
    })
    this.hitbox.name = "cross"
    this.hitbox.setParent(this.object)
  }

  show() {
    gsap.to(this.uniforms.opacity, {
      value: 1,
      duration: 0.2,
      ease: "sine.inOut",
      onStart: () => {
        if (this.mesh) {
          this._visible = true
          this.mesh.visible = true
          this.hitbox.visible = true
        }
      },
    })
  }

  hide() {
    gsap.to(this.uniforms.opacity, {
      value: 0,
      duration: 0.2,
      ease: "sine.inOut",
      onComplete: () => {
        if (this.mesh) {
          this._visible = false
          this.mesh.visible = false
          this.hitbox.visible = false
        }
      },
    })
  }

  update() {
    if (this.mesh) {
      this.mesh.rotation.y -= 0.0075
      this.mesh.program.uniforms.uTime.value += 0.0005

      this._scale.value +=
        (this._scale.target * this._scale.transform - this._scale.value) * 0.1
      this.mesh.scale.set(this._scale.value)
    }
  }

  set hover(hover) {
    if (this._hover === hover) return

    if (this._hover) {
      gsap.killTweensOf(this._hover.program.uniforms.hover)
      gsap.to(this._hover.program.uniforms.hover, {
        value: 0,
        duration: 0.2,
        ease: "cubic.out",
      })
    }
    if (hover) {
      gsap.killTweensOf(hover.program.uniforms.hover)
      gsap.to(hover.program.uniforms.hover, {
        value: 1,
        duration: 0.2,
        ease: "cubic.out",
      })
    }

    this._hover = hover
    this._scale.target = hover ? this._scale.default * 1.1 : this._scale.default
  }

  set visible(visible) {
    if (visible) {
      this.show()
    } else {
      this.hide()
    }
  }

  get visible() {
    return this._visible
  }
}
