import { gsap } from "gsap"
// import { CustomEase } from "../../../utils/custom-ease"
// import VirtualScroll from "virtual-scroll"
import React from "react"
import { navigate, StaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"
import { Renderer, Camera, Transform, Post, Vec2, Raycast } from "ogl"
import { bindAll } from "lodash"
import Cube from "./cube"
import Cross from "./cross"
import fxaaFragment from "../../../utils/fxaa"
import * as MSDF from "../../../utils/msdf"
import { delay } from "../../../utils"
import {
  toggleInfo,
  toggleProjects,
  toggleCredits,
  setRotationSourceTouch,
  setRotationSourceScroll,
} from "../../state/store"
import * as clippingPlane from "./clipping-plane"

// gsap.registerPlugin(CustomEase)
const compare = (nextState, state) => (key) => {
  return nextState[key] !== state[key]
}

const DRAG = 0.5
const STRENGTH = 0.05
// CustomEase.create("speichert", "0.075, 0.820, 0.165, 1.000")

class ThirdDimension extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
    this.project = props.project

    this.mapStateToProps = (state) => ({ todos: state.todos })

    this.dispatch = props.dispatch
    this.canvas = React.createRef()

    this.rotationMultiplier = this.props.rotationMultiplier || 1
    this.rotationSource = this.props.rotationSource || "scroll"
    this.fontSize = 10
    this.projectsModalHeight = 0
    this.infoModalHeight = 0
    this.modalHeight = 0
    this.infoModal = undefined
    this.eventsAdded = false
    this.modalVisible = false

    this._scroll = {
      delta: 0,
      target: 0,
      velocity: 0,
      value: 0,
    }

    bindAll(this, [
      "resize",
      "update",
      "handlePointerMove",
      "handleClick",
      "handleModalScroll",
      "handleTouchmove",
      "handleWheel",
    ])
  }

  async init(canvas) {
    const { projects, hasProtectedAccess } = this.props

    this.renderer = new Renderer({
      canvas,
      alpha: true,
      antialias: true,
      dpr: Math.min(window.devicePixelRatio || 1, 2),
    })
    this.gl = this.renderer.gl

    this.camera = new Camera(this.gl)
    this.camera.position.z = 5

    this.scene = new Transform()

    this.mouse = new Vec2()
    this.raycast = new Raycast(this.gl)

    this.resolution = { value: new Vec2() }
    this.post = new Post(this.gl)

    this.pass = this.post.addPass({
      fragment: fxaaFragment,
      transparent: true,
      uniforms: {
        uResolution: this.resolution,
      },
    })

    await MSDF.createProgram(this.gl, this.renderer)

    this.cross = new Cross({
      gl: this.gl,
      parent: this.scene,
    })

    this.cube = await new Cube({
      gl: this.gl,
      parent: this.scene,
      page: this.props.location,
      projects,
    }).init(this.gl, this.scene)
    this.cube.mesh.rotation.x = Math.PI

    window.addEventListener("resize", this.resize, false)
    this.resize()

    // lazyload to avoid server
    const { default: VirtualScroll } = await import("virtual-scroll")
    this.vs = new VirtualScroll({
      el: document,
      touchMultiplier: 1,
    })

    requestAnimationFrame(this.update)

    await delay(100)
    await this.cube.animateIn()
    document.documentElement.style.backgroundColor = "#000"
    document.body.querySelector(".layout__content").style.visibility = "visible"
    await this.cube.start()
    this.addEvents()
    document.addEventListener("click", this.handleClick, false)
    document.addEventListener("mousemove", this.handlePointerMove, false)
    document.addEventListener("touchmove", this.handlePointerMove, false)

    this.ready = true
  }

  addEvents() {
    if (this.eventsAdded) return
    this.eventsAdded = true
    // document.addEventListener("click", this.handleClick, false)
    this.vs.on(this.handleVirtualScroll, this)
    document.addEventListener("touchmove", this.handleTouchmove)
    document.addEventListener("wheel", this.handleWheel)
  }

  removeEvents() {
    if (!this.eventsAdded) return
    this.eventsAdded = false
    // document.removeEventListener("click", this.handleClick, false)
    document.removeEventListener("touchmove", this.handleTouchmove)
    document.removeEventListener("wheel", this.handleWheel)
    this.vs.off(this.handleVirtualScroll, this)
  }

  hitTest() {
    this.raycast.castMouse(this.camera, this.mouse)
    const meshes = this.cross.visible ? this.cross.hitbox : this.cube.hitBoxes
    const hits = this.raycast.intersectBounds(meshes)
    return hits
  }

  handleTouchmove() {
    if (this.rotationSource == "scroll") this.dispatch(setRotationSourceTouch())
  }

  handleWheel() {
    if (this.rotationSource == "touch") this.dispatch(setRotationSourceScroll())
  }

  handleClick(e) {
    this.mouse.set(
      2.0 * (e.x / this.renderer.width) - 1.0,
      2.0 * (1.0 - e.y / this.renderer.height) - 1.0
    )

    let hits = this.hitTest()

    if (hits.length) {
      hits = hits.filter((obj) => obj.visible)
      const { hasProtectedAccess } = this.props
      const { project } = this
      switch (hits[0].name) {
        // if(this.cube) this.cube.hover = false;
        case "info":
          e.preventDefault()
          this.dispatch(toggleInfo(true))
          break
        case "work":
          e.preventDefault()
          this.dispatch(toggleProjects(true))
          break
        case "close":
          e.preventDefault()
          navigate("/")
          break
        case "credits":
          e.preventDefault()
          ;(!project.current.frontmatter.protected || hasProtectedAccess) &&
            this.dispatch(toggleCredits(true))
          break
        case "previous-project":
          e.preventDefault()
          project && navigate(project.prev.node.frontmatter.slug)
          break
        case "next-project":
          e.preventDefault()
          project && navigate(project.next.node.frontmatter.slug)
          break
        case "cross":
          e.preventDefault()
          this.dispatch(toggleInfo(false))
          this.dispatch(toggleProjects(false))
          this.dispatch(toggleCredits(false))
          break
        default:
          e.preventDefault()
          navigate(
            hits[0].name.startsWith("/") ? hits[0].name : `/${hits[0].name}`
          )
          break
      }
    }
  }

  handlePointerMove(e) {
    this.mouse.set(
      2.0 * (e.x / this.renderer.width) - 1.0,
      2.0 * (1.0 - e.y / this.renderer.height) - 1.0
    )

    if ("onmousemove" in window) {
      let hits = this.hitTest()

      if (hits.length) {
        hits = hits.filter((obj) => obj.visible)
        if (hits.length) {
          document.body.style.cursor = "pointer"
          document.body.classList.add("pointer")
          if (this.cross.visible) {
            this.cross.hover = hits[0]
          } else {
            this.cube.hover = hits[0]
          }
          return
        }
      }
      document.body.style.cursor = ""
      document.body.classList.remove("pointer")
      this.cube.hover = false
      this.cross.hover = false
    }
  }

  handleModalScroll(e) {
    clippingPlane.uniforms.uClip.value =
      (this.modalHeight + this.infoModal.scrollTop) / window.innerHeight
  }

  handleVirtualScroll(e) {
    this.cube._rotation.direction = Math.sign(e.deltaY)
    this._scroll.target =
      e.y * (this.rotationSource == "touch" ? this.rotationMultiplier : 1)
  }

  update(t) {
    requestAnimationFrame(this.update)

    if (!this.modalVisible) {
      let delta = this._scroll.target - this._scroll.value
      delta *= STRENGTH
      this._scroll.velocity *= DRAG
      this._scroll.velocity += delta
      this._scroll.value += this._scroll.velocity
    }
    this.cube._rotation.scroll = this._scroll.value * 0.002

    this.cube.update({ modalVisible: this.modalVisible })
    this.cross.update()

    this.post.render({ scene: this.scene, camera: this.camera })
  }

  resize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.camera.perspective({
      aspect: this.gl.canvas.width / this.gl.canvas.height,
    })

    this.cube.resize({
      width: this.gl.canvas.width,
      height: this.gl.canvas.height,
      camera: this.camera,
    })
    this.post.resize()
    this.resolution.value.set(this.gl.canvas.width, this.gl.canvas.height)

    this.fontSize = Number(
      getComputedStyle(document.documentElement).fontSize.replace("px", "")
    )

    this.projectsModalHeight =
      window.innerWidth < 768
        ? window.innerWidth * 0.888 * 0.6667 + 10 + 24 + 15 + 6
        : window.innerWidth * 0.416 * 0.6667 + 5.75 * this.fontSize + 6
    this.infoModalHeight =
      window.innerWidth < 768
        ? window.innerHeight - window.innerWidth * 0.375
        : window.innerHeight * 0.5
  }

  async componentDidMount() {
    // console.log("3D: componentDidMount()", "", this.props)
    await this.init(this.canvas.current)
    // this.resize()
  }

  shouldComponentUpdate(props) {
    // console.log("3D: shouldComponentUpdate()")
    const diff = compare(props, this.props)

    if (
      diff("project") ||
      (props.project &&
        props.project.current.frontmatter.slug !==
          this.project.current.frontmatter.slug)
    ) {
      this.project = props.project
    }

    if (diff("rotationMultiplier")) {
      this.rotationMultiplier = props.rotationMultiplier
    }

    if (diff("rotationSource")) {
      this.rotationSource = props.rotationSource
    }

    if (!this.ready) return false

    if (diff("location")) {
      this.cube.page = props.location
    }

    this.modalVisible =
      props.showInfo || props.showProjects || props.showCredits
    const duration = 0.46 //this.modalVisible ? 0.625 : 0.575

    if (this.cube && this.cube.uniforms) {
      gsap.to(this.cube.uniforms.uBrightness, {
        value: Number(!this.modalVisible) * 0.8 + 0.2,
        duration,
        ease: "expo.out",
      })
    }
    if (this.modalVisible) {
      this.cross.visible = true
      this.cube.hover = false
      this.removeEvents()
    } else {
      this.cross.visible = false
      this.addEvents()
    }

    if (props.showInfo || props.showCredits) {
      this.infoModal = props.showInfo
        ? document.body.querySelector(".index__info .modal__wrapper")
        : document.body.querySelector(".project__credits .modal__wrapper")

      this.modalHeight = Math.round(
        Math.min(
          this.infoModalHeight,
          this.infoModal.querySelector(".modal__content").clientHeight
        )
      )

      const margin = window.innerWidth < 768 ? 10 : 20
      clippingPlane.uniforms.uMargin.value = margin / window.innerWidth

      gsap.killTweensOf(clippingPlane.uniforms.uClip)
      gsap.to(clippingPlane.uniforms.uClip, {
        value:
          (this.modalHeight + this.infoModal.scrollTop) / window.innerHeight,
        duration,
        ease: "expo.out",
        onComplete: () => {
          this.infoModal.addEventListener(
            "scroll",
            this.handleModalScroll,
            false
          )
        },
      })
      return false
    } else {
      if (this.infoModal)
        this.infoModal.removeEventListener(
          "scroll",
          this.handleModalScroll,
          false
        )
    }

    if (props.showProjects) {
      clippingPlane.uniforms.uMargin.value = 0
      gsap.killTweensOf(clippingPlane.uniforms.uClip)
      gsap.to(clippingPlane.uniforms.uClip, {
        value: this.projectsModalHeight / window.innerHeight,
        duration,
        ease: "expo.out",
      })
      return false
    }

    gsap.killTweensOf(clippingPlane.uniforms.uClip)
    gsap.to(clippingPlane.uniforms.uClip, {
      value: 0,
      duration,
      ease: "expo.out",
    })

    gsap.set(clippingPlane.uniforms.uClip, {
      value: 0,
      onComplete: () => {
        clippingPlane.uniforms.uMargin.value = 0
      },
    })

    return false
  }

  render() {
    // console.log("3D: render()", "")
    return (
      <div className="third-dimension">
        <canvas ref={this.canvas}></canvas>
      </div>
    )
  }
}

export const query = graphql`
  query {
    projects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 100
      filter: { frontmatter: { title: { ne: "index" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            video_thumbnail
          }
        }
      }
    }
  }
`

const ThirdDimensionWithQuery = function (props) {
  return (
    <StaticQuery
      query={query}
      render={({ projects }) => (
        <ThirdDimension projects={projects.edges} {...props} />
      )}
    />
  )
}

export default connect(
  (state) => ({
    showInfo: state.showInfo,
    showProjects: state.showProjects,
    showCredits: state.showCredits,
    location: state.location,
    project: state.project,
    hasProtectedAccess: state.hasProtectedAccess,
    rotationMultiplier: state.rotationMultiplier,
    rotationSource: state.rotationSource,
  }),
  null
)(ThirdDimensionWithQuery)
