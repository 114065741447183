import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

class Fade extends React.Component {
  render() {
    return (
      <TransitionLink
        to={this.props.to}
        exit={{
          length: this.props.exit
        }}
        entry={{
          length: this.props.entry,
          delay: this.props.exit
        }}

        className={this.props.className}
        activeClassName="is-active"
        onClickCapture={this.props.onClick}
        partiallyActive
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
      >
        {this.props.children}
      </TransitionLink>
    )
  }
}

Fade.defaultProps = {
  entry: .6,
  exit: .6
}

export default Fade